html,
body {
    height: auto !important;
}

body {
    margin: 0;
    /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;*/
    font-family: SF Pro Text !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

@font-face {
    font-family: 'SF Pro Text';
    src: url('fonts/SFProText-Regular.woff2') format('woff2'),
        url('fonts/SFProText-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


.button_wrap .Polaris-Button--primary {
    background: #008060 !important;
    border-color: #008060 !important;
    box-shadow: none !important;
}

.button_wrap .Polaris-Button--primary:hover {
    background: #006e52 !important;
    border-color: #008060 !important;
}

.Polaris-Link,
.Polaris-Link:hover,
.Polaris-Link:focus {
    text-decoration: none !important;
}

.preview-bar {
    // background-color: #010101; 
    // color: #fff;
    padding: 10px;
    margin-bottom: 5px;
    // text-align: center;
}

.m-t-10 {
    margin-top: 10px;
}

.m-t-30 {
    margin-top: 30px;
}

.m-t-40 {
    margin-top: 40px;
}

.m-t-50 {
    margin-top: 50px;
}

.left-alignment {
    .Polaris-Layout {
        justify-content: left;
        margin-left: 2px;
    }
}

.form-input-grp-margin {
    margin-top: -15px;
    margin-left: -20px;
}

.Polaris-Frame-No-Height .Polaris-Frame {
    min-height: auto !important;
}

.group-input-handle {
    margin-left: -18px;
    margin-top: -18px;
}

.color-box {
    border: 1px solid #ddd;
    width: 20px;
    height: 20px;
    display: inline-block;
    margin-top: 7px;
    margin-right: 12px;
}

.arrow-down {
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #010101;
    display: inline-block;
    position: absolute;
    top: 15px;
    right: -4px;
}

.color-box-wrapper .Polaris-TextField__Suffix>div:focus {
    outline: none !important;
}

.bar-preview-input .Polaris-Label {
    width: 100%;
    text-align: center;
}

.devide-line {
    display: inline-block;
    width: 97.6%;
    margin: 15px 0 20px 0;
    box-sizing: border-box;
    border-top: solid 1px #c4cdd5;
}

.border-block {
    position: relative;
}

.vertical-border {
    height: 410px;
    border-right: solid 1px #ddd;
    position: absolute;
    right: -8px;
    top: 20px;
}

.accordion-arrow {
    position: absolute;
    right: 0;
    top: 0;
}

.poz-rel {
    position: relative;
}

.text-collapse {
    .Polaris-Collapsible--open {
        opacity: 1;
        margin: 11px 0 0 15px;
    }
}

.date-range-wrapper {
    height: auto;
    width: auto;
    padding: 10px;
}

.product-cartbar-offer .Polaris-Card {
    margin-right: 20px;
}

.cart-bar-settings .Polaris-Subheading {
    text-transform: capitalize;
    font-size: 14px;
}

.helper-text {
    color: var(--p-text-subdued, #637381);
}

/* css starts 1-10-20 */

.hlanding_page .Polaris-EmptyState__Section {
    flex-direction: row-reverse;
}

.hlanding_page {
    background-image: url('home-img.jpg');
    background-size: 100%;
    background-repeat: no-repeat;
}

.hlanding_page .Polaris-EmptyState {
    margin-top: 50px;
    padding-top: 150px;
    padding-bottom: 180px;
}

.hlanding_page .Polaris-EmptyState--withinPage .Polaris-EmptyState__Details {
    margin-left: 70px;
    margin-top: -30px;
    width: 30.0rem;
}

.hlanding_page .Polaris-TextContainer>*:not(:first-child) {
    margin-top: 8px;
}

.hlanding_page .Polaris-Button__Content {
    padding: 0 25px
}

/* second landing page css */

.hlanding_pagetwo .Polaris-EmptyState__Section {
    flex-direction: row;
}

.hlanding_pagetwo {
    background-image: url('home-img2.png');
    background-size: 100%;
}

.hlanding_pagetwo .Polaris-EmptyState {
    margin-top: 50px;
    padding-top: 150px;
    padding-bottom: 120px;
}

.hlanding_pagetwo .Polaris-DisplayText {
    color: #fff
}

.hlanding_pagetwo .Polaris-EmptyState__Content {
    color: #fff
}

.hlanding_pagetwo .Polaris-EmptyState__Details {
    padding-left: 35px
}

.hlanding_pagetwo .Polaris-EmptyState--withinPage .Polaris-EmptyState__Details {
    padding: 0 25px;
}

.hlanding_pagetwo .Polaris-Button--primary {
    background-color: #fff;
    background: #fff;
    border-color: #c5c4c4;
    box-shadow: none;
}

.hlanding_pagetwo .Polaris-Button--primary:hover {
    background-color: #eee;
    background: #eee;
    border-color: #c5c4c4;
    box-shadow: none;
}

.hlanding_pagetwo .Polaris-Button__Text {
    color: #000;
    padding: 0 25px
}

/* product listing page css */

.myprdctlist .Polaris-Thumbnail__Image {
    margin-top: 0;
}

.myprdctlist .pdesc .Polaris-Stack__Item.Polaris-Stack__Item--fill:first-child {
    color: #007ace;
}

.myprdctlist .pdesc .Polaris-Stack__Item {
    color: #688
}

.myprdctlist .pqty .Polaris-Stack__Item {
    color: #000
}

.myprdctlist .Polaris-Button--primary {
    padding: 0 30px;
    float: right;
    margin: 30px;
    margin-top: 5px;
}

.myprdctlist .Polaris-Stack>.Polaris-Stack__Item {
    min-width: 15%;
}

.myprdctlist .Polaris-Stack>.Polaris-Stack__Item:first-child {
    min-width: 60%;
}


.myprdctlist .Polaris-Stack>.Polaris-Stack__Item:last-child {
    text-align: right;
}

.myprdctlist .Polaris-Stack {
    flex-wrap: nowrap;
}

.myprdctlist .Polaris-Stack__Item {
    flex: auto;
}

.pthumbOutr {
    position: relative;
}

.pthumbOutr span.pthumbQty {
    position: absolute;
    top: -6px;
    right: -10px;
    z-index: 99;
    background: #dfe3e8;
    padding: 1px 10px;
    border-radius: 15px;
    font-size: 11px;
    line-height: normal;
    font-weight: bold;
    border: 1px solid #fff;
}

.myprdctlist {
    margin-top: 20px;
}

/*
.myprdctlist .pqty, .myprdctlist .ptamount{
    max-height: 100%;
    vertical-align: middle;
    display: table-cell;
    height: 50px;
}*/

/* top tabs css */
.top-tabs .Polaris-Tabs__Tab.Polaris-Tabs__Tab--selected span {
    font-weight: normal;
    color: #000;
}

.top-tabs span.Polaris-Tabs__Title {
    color: #444;
    bottom: -4px;
}

.top-tabs button span.Polaris-Tabs__Title {
    color: #444;
    bottom: -0px;
}

.upsells-outr .Polaris-Page .Polaris-DisplayText.Polaris-DisplayText--sizeLarge {
    font-size: 20px;
    font-weight: 500;
    color: #212B36;
}

.upsells-outr .Polaris-DisplayText--sizeSmall {
    color: #212B36;
    font-size: 16px;
    font-weight: 500;
}

.upsells-outr .Polaris-TextStyle--variationSubdued {
    color: #999;
    font-size: 12px;

}

.edit-shpng-ofr .Polaris-Page .Polaris-DisplayText.Polaris-DisplayText--sizeLarge {
    font-size: 16px;
    font-weight: 500;
    color: #212B36;
}

.faq-outr .Polaris-Page .Polaris-DisplayText.Polaris-DisplayText--sizeLarge {
    font-size: 18px;
    font-weight: 500;
    color: #212B36;
}

.edit-shpng-ofr .Polaris-Heading {
    font-size: 18px;
    font-weight: normal;
    color: #000;
}

.edit-shpng-ofr .preview-bar {
    font-size: 10px;
}

.crt-boostbar .Polaris-DisplayText--sizeSmall {
    font-size: 20px;
    color: #637381;
    margin-bottom: 10px;
}

.edit-coupon-ofr .Polaris-Page .Polaris-DisplayText.Polaris-DisplayText--sizeLarge {
    font-size: 16px;
    font-weight: 500;
    color: #212B36;
}

.edit-coupon-ofr .Polaris-Heading {
    font-size: 18px;
    font-weight: normal;
    color: #000;
}

.edit-prdct-ofr .Polaris-Page .Polaris-DisplayText.Polaris-DisplayText--sizeLarge {
    font-size: 16px;
    font-weight: 500;
    color: #212B36;
}

.edit-prdct-ofr .Polaris-Heading {
    font-size: 18px;
    font-weight: normal;
    color: #000;
}

.edit-prdct-ofr .preview-bar {
    font-size: 10px;
}

.faq-outr .Polaris-Page__Content .Polaris-Page h1.Polaris-DisplayText {
    font-size: 18px;
    color: #444;
    font-weight: normal;
}

.faq-outr .Polaris-TextContainer {
    color: #444;
}

.incart-upsells-outr .Polaris-Page .Polaris-DisplayText.Polaris-DisplayText--sizeLarge {
    font-size: 20px;
    font-weight: 500;
    color: #212B36;
}

.incart-upsells-outr .Polaris-Link {
    font-size: 16px;
    font-weight: normal;
}

.incart-upsells-outr .Polaris-TextStyle--variationSubdued {
    font-size: 12px;
    color: #999;
}

.edit-incart-upsells .Polaris-Page .Polaris-DisplayText.Polaris-DisplayText--sizeLarge {
    font-size: 20px;
    font-weight: 500;
    color: #212B36;
}

.edit-incart-upsells .Polaris-Heading {
    font-size: 16px;
    font-weight: normal;
    color: #212B36;
}

.add-incart-upsells .Polaris-Page .Polaris-DisplayText.Polaris-DisplayText--sizeLarge {
    font-size: 20px;
    font-weight: 500;
    color: #212B36;
}

.main-pge-outr .Polaris-Button__Text {
    font-size: 12px;

}

.own-shadow {
    box-shadow: 0 0 9px -1px #292828;
    border-radius: 5px;
}

.oneT-ofr-outr {
    width: 100%;
    margin: 0 auto;
    float: none;
    /*display: flex;
    flex: 0 0 auto;*/
}

.ofr-outr-left {
    /*display: flex;*/
    text-align: center;
    width: 40%;
    float: left;
    position: relative;
    height: 400px;
}

.ofr-outr-right {
    text-align: center;
    padding: 0 2.5%;
    /*display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    align-self: stretch;*/
    width: 60%;
    float: left;
    padding-right: 0;
    padding-bottom: 20px;
}

.ofr-outr-left img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    display: inline-block;
    max-height: 100%;
    max-width: 100%;
}

.ofr-outr-right h2,
.ofr-outr-left h2 {
    color: #FF0000;
    font-size: 25px;
    text-transform: uppercase;
    margin-bottom: 20px;
    line-height: 28px;
}

.ofr-outr-right p,
.ofr-outr-left p {
    color: #5E5E5E;
    font-size: 15px;
    line-height: 24px;
}

.ofr-outr-right .prdct-detail h3 {
    font-size: 25px;
    color: #000;
    display: block;
    width: 100%;
}

.ofr-outr-right .prdct-detail span {
    font-size: 14px;
    color: #707070;
    display: block;
    width: 100%;
    margin: 15px 0;
}

.ofr-outr-right .prdct-detail span.p-price {
    font-size: 20px;
    color: #000;
    display: block;
    width: 100%;
    margin-top: 30px;
}

.prdct-btns {
    margin-top: 35px;
    display: block;
    width: 100%;
}

.ofr-outr-right .white-btn {
    padding: 15px 30px;
    font-size: 21px;
    border: 0.1rem solid var(--p-border, #c4cdd5);
    background: linear-gradient(to bottom, white, #f9fafb);
    border-radius: 5px;
    color: #212B36;
    text-decoration: none;
    font-size: 21px;
    margin: 0 10px;
    display: inline-block;
    cursor: pointer;
}

.ofr-outr-right .blue-btn {
    padding: 15px 30px;
    font-size: 21px;
    border: 1px solid #3E4BB1;
    border-radius: 5px;
    color: #fff;
    text-decoration: none;
    font-size: 21px;
    background-color: #6371c7;
    margin: 0 10px;
    display: inline-block;
    cursor: pointer;
}

.cartboost-app-menu .Polaris-Tabs__Title span .Polaris-Icon {
    margin: 0 5px;
}

.main-menu-tabs .Polaris-Tabs__Tab {
    padding: 0 10px;
}

.main-menu-tabs .Polaris-Tabs__Tab svg.Polaris-Icon__Svg {
    fill: #5C5F62;
}


.main-menu-tabs .Polaris-Tabs__Tab.Polaris-Tabs__Tab--selected svg.Polaris-Icon__Svg {
    fill: #3E4BB1;
}

.prdct-detail {
    margin-top: 35px;
}

.ofr-outr-left h2,
.ofr-outr-left p {
    display: none;
}

.new-coll-outr .Polaris-Card {
    float: left;
    width: 100%;
}

.new-coll-outr .Polaris-Card__Header {
    padding: 2rem;
}

.new-coll-outr .Polaris-Button {
    float: right;
    margin: 30px;
    padding: 0 30px;
}

.offer_wrapper {
    display: flex;
    padding: 15px;
    clear: both;
    justify-content: center;
    max-width: 80%;
}

.compare-price {
    text-decoration: line-through;
    color: #999;
}


.image_wrap {
    width: 100px;
    display: inline-block;
    margin-right: 10px;
}

.offer_text h3 {
    font-weight: bold;
    text-transform: uppercase;
    margin: 0 auto 10px;
}

.offer_text p {
    font-size: 14px;
}

.image_wrap img {
    width: 100%;
    // height: 100%;
}

.offer_text {
    color: #333 !important;
    font-size: 16px;
    vertical-align: middle;
    width: calc(100% - 150px);
    display: flex;
}

.button_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.OfferBtn,
.OfferBtn:hover,
.OfferBtn:active,
.OfferBtn:focus {
    text-transform: uppercase;
    background-color: #6371c7;
    padding: 10px 8px;
    color: #fff;
    font-size: 15px;
    border-radius: 4px;
    margin-left: 20px;
    text-align: center;
    width: 100%;
    display: inline-block;
    border: none;
    min-width: 160px;
}

.range-slider-helper {
    min-width: 24px;
    text-align: center;
}

.button_wrap button[type=submit],
.button_wrap button[type=button] {
    margin-bottom: 10px;
    margin-right: 10px;
    margin-left: 0;
}

.prgrs-outr {
    display: flex;
    width: 100%;
    position: relative;
    margin: 0 auto;
    flex-direction: row;
    align-items: center;
}

.progress-container {
    height: 5px;
    width: 100%;
    background-color: rgb(224, 224, 222);
    border-radius: 50px;
    display: inline-block;
    vertical-align: middle;
}

.filler-style {
    height: 100%;
    width: 50%;
    background-color: rgb(92, 106, 196);
    border-radius: inherit;
    text-align: right;
    /*border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 2px solid;*/
    position: relative;

}

.prgrs-outr .start_point,
.prgrs-outr .end_point {
    margin: 0 5px;
}

/*.prgrs-outr .start_point {
    position: absolute;
    left: -35px;
    top: 2px;
}

.prgrs-outr .end_point {
    position: absolute;
    right: -35px;
    top: 2px;
}
*/
.prgrs-outr .circle-style {
    padding: 5px;
    color: white;
    font-weight: bold;
    background: #fff;
    border-radius: 100px;
    width: 28px;
    height: 28px;
    display: inline-block;
    top: -10px;
    position: absolute;
    /*margin-left: -7px;*/
    border: 1px solid #ccc;
    right: -3px;
}

.prgrs-outr .icon-style {
    color: white;
    font-weight: bold;
    background: transparent;
    border-radius: 100px;
    width: 50px;
    height: 50px;
    display: inline-block;
    top: -13px;
    position: absolute;
    /*margin-left: -15px;*/
    background-image: url('rocket-emji.png');
    background-repeat: no-repeat;
    background-size: 45px;
    right: -14px;
}

.upgrade-plan {
    background-color: #195fc7;
    width: 100%;
    height: 100%;
    color: #fff;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    margin-bottom: -32px;
    display: flex;
    justify-content: center;
}

.upgrade-plan-content {
    display: flex;
    align-items: center;
    font-size: 18px;
}

.upgrade-plan-content span {
    margin-right: 12px;
}

.upgrade-plan-content .Polaris-Icon svg {
    fill: #ffffff !important;
    width: 25px;
}

.mobile-upgrade-plan .upgrade-plan-content .Polaris-Icon svg {
    fill: #195fc7 !important;
    width: 25px;
}

.mobile-upgrade-plan .upgrade-plan-content {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #195fc7;
}

.upgrade-plan-button .Polaris-Icon svg {
    fill: #195fc7 !important;
}

.Menu-Links li.active .mobile-upgrade-plan .upgrade-plan-content .Polaris-Icon svg {
    fill: #fff !important;
}

.Menu-Links li.active .mobile-upgrade-plan .upgrade-plan-content {
    color: #fff;
}

.bar-preview-text,
.bar-current-value {
    text-align: center;
    font-size: 15px;
    display: block;
    padding: 10px 0;
}

.inCart_desc_outr {
    display: inline-flex;
    // margin-left: -15px;
}

.separation-line {
    padding-bottom: 1.6rem;
    border-bottom: var(--p-override-none, .1rem solid var(--p-border-subdued, #dfe3e8));
}

.popup-manage .Polaris-Page .Polaris-DisplayText.Polaris-DisplayText--sizeLarge {
    font-size: 20px;
    font-weight: 500;
    color: #212B36;
}


.up-trigger-types .Polaris-ChoiceList__Choices {
    display: flex;
    align-items: center;
    align-content: center;
}

.popup-manage .button_wrap,
.popup-settings .button_wrap {
    margin-top: 20px;
    margin-bottom: 20px;
}

.card-border-color .Polaris-Card {
    border-top: 3px solid #637381;
}

.card-border-color .Polaris-TextStyle--variationStrong {
    position: relative;
    padding-left: 35px;
    padding-top: 5px
}

.card-border-color .Polaris-TextStyle--variationStrong:before {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    background-image: url('upselltrigger.png');
    background-repeat: no-repeat;
    background-size: 16px;
    height: 25px;
    width: 25px;
    background-color: #dfe3e8;
    padding: 5px;
    border-radius: 100px;
    background-position: center;
}

.card-border-color .Polaris-ChoiceList__Choices li {
    display: inline-block;
    width: 100%;
    border: 1px solid #c4cdd5;
    border-radius: 2px;
    padding: 3px 8px;
}

.up-trigger-types .Polaris-ChoiceList__Choices li:first-child {
    margin-right: 2%;
}

.up-trigger-types .Polaris-Choice__Control {
    order: 2;
    float: right;
    margin-right: 0;
    margin-left: 10px;
}

#upsell-trigger-collapsible .upsell-trigger-wrapper .Polaris-FormLayout__Items {
    align-items: flex-end;
}

.upcoming-badge span {
    display: inline-block;
    padding: 2px 10px;
    background: #b4e1fa;
    border-radius: 25px;
    font-size: 12px;
}

.up-trigger-types .Polaris-Choice__Label {
    order: 1;
}

.up-trigger-types .Polaris-Choice {
    display: inline-block;
    width: 100%;
    position: relative;
    padding-left: 30px;
}

.up-trigger-types .Polaris-Choice:before {
    position: absolute;
    top: 2px;
    left: 0;
    content: "";
    background-image: url('upselltrigger.png');
    background-repeat: no-repeat;
    background-size: 16px;
    height: 25px;
    width: 25px;
    background-color: #dfe3e8;
    padding: 5px;
    border-radius: 100px;
    background-position: center;
}

.up-trigger-types .Polaris-Card .Polaris-Card {
    border: 0;
}

.up-trigger-types .Polaris-TextStyle--variationSubdued {
    padding: 10px 20px;
    display: inline-block;
}

.card-border-color #upsell-trigger-collapsible .Polaris-Card,
.card-border-color #upsell-offer-collapsible .Polaris-Card {
    border: 0px;
}

.up-trigger .Polaris-TextStyle--variationStrong:before {
    background-image: url('upselltrigger.png');
}

.up-trigger-types .Polaris-Choice:before {
    background-image: url('addtocart.png');
}

.up-trigger-types.checkout-type li .Polaris-Choice:before {
    background-image: url('checkout.png');
}

.up-trigger-types.discount-type li .Polaris-Choice:before {
    background-image: url('upselloffer.png');
}

.up-trigger-types.product-type li .Polaris-Choice:before {
    background-image: url('ProductsMajor.svg');
}

.up-offer .Polaris-TextStyle--variationStrong:before {
    background-image: url('upselloffer.png');
}

.popup-manage .Polaris-Heading,
.popup-settings .Polaris-Heading {
    font-weight: normal;
}

.cartboost-app-menu .Polaris-Card {
    display: flex;
    // align-items: flex-end;
    padding: 5px 10px 0;
}

.logo-div-sec {
    display: flex;
    max-width: 150px;
    align-items: center;
}

.logo-div-sec img {
    max-width: 100%;
}


.cartboost-app-menu .Polaris-Tabs__Wrapper {
    border-bottom: 0px;
}

.cartboost-app-menu .Polaris-Tabs__Title {
    min-height: 56px;
}

.upsell-trigger-wrapper {
    margin-left: -20px;
}

.trigger-description {
    padding: 5px 20px 0;
}

.font-family-wrapper {
    div[id^=font-picker] {
        width: 100%;
        top: 5px;
        box-shadow: none;
    }

    div[id^=font-picker] .dropdown-button,
    div[id^=font-picker] .dropdown-button:focus,
    div[id^=font-picker] .dropdown-button:hover {
        background: transparent;
        border: 1.5px solid #dedede;
        border-radius: 3px;
    }

    div[id^=font-picker] ul {
        background-color: #fff;
        z-index: 9999;
    }
}

.incart-upsells-outr .Polaris-Stack__Item,
.incart-upsells-outr .Polaris-Stack {
    display: block;
}

.message-conditions .Polaris-Icon {
    margin: 0;
}

.more-actions img {
    height: 28px;
    cursor: pointer;
}

.flex-between {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
    align-items: center;
}

.sidebar-menu.flex-between {
    padding: 0;
}

.Menu-Links ul {
    padding: 0px;
    margin: 0;
    list-style: none;
}

.Menu-Links ul button.Polaris-Link {
    font-weight: 600;
    font-size: 1.5rem;
    padding: 18px 15px;
    width: 100%;
    border-bottom: 1px solid #dedede;
    color: #333;
    display: flex;
    justify-content: space-between;
}

.Menu-Links ul .active button.Polaris-Link {
    background-color: #3f4eae;
    color: #fff;
}

.Menu-Links ul .active button.Polaris-Link span.Polaris-Icon svg {
    fill: #fff;
}

.Menu-Links ul button.Polaris-Link>span {
    display: flex;
}

.Menu-Links ul button.Polaris-Link span.Polaris-Icon {
    margin: 0 12px;
}

.Menu-Links ul li:hover {
    background-color: #8493f7;
}

.Menu-Links ul li:hover button.Polaris-Link span.Polaris-Icon svg {
    fill: #fff;
}

.Menu-Links ul li:hover button.Polaris-Link {
    color: #fff;
}

.Polaris-Sheet {
    max-width: 38rem;
    right: 0;
}

.cartboost-app-menu .Polaris-Tabs__Title span {
    display: flex;
}

.custom-icon-radio .icon-outer {
    margin: 0;
    background: #dfe3e8;
    border-radius: 50px;
    width: 25px;
    height: 25px;
    display: flex;
}

.custom-icon-radio {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    align-items: center;
    border: 1px solid #ddd;
    padding: 5px 10px;
}

.custom-icon-radio .Polaris-Icon svg {
    fill: #353535;
}

.custom-icon-radio .icon-outer .Polaris-Icon {
    width: 1.5rem;
    height: 1.5rem;
}

.HomepageContent {
    text-align: center;
    // max-width: 750px;
    margin: 0 auto;
    color: #424548;
    padding: 5% 0 20px;
}

.HomepageContent .logo-div-sec {
    justify-content: center;
    align-items: center;
    max-width: unset;
    padding: 10px;
    margin: 30px auto;
}

.HomepageContent .logo-div-sec img {
    max-width: 200px;
}

.HomepageContent .Polaris-Heading {
    font-size: 30px;
    margin: 10px auto;
    font-weight: 600;
}

.HomepageContent .Polaris-Heading sup {
    font-size: 40%;
    padding: 2px;
}

hr.blue-border {
    border: 4px solid #1640a5;
    max-width: 150px;
    margin: 30px auto 40px;
}

.HomepageContent .Polaris-Subheading {
    margin: 25px auto 50px;
    font-size: 22px;
    text-transform: unset;
    line-height: normal;
}

.icons-area .inner-blk {
    font-size: 16px;
    font-weight: 500;
    margin: 15px auto;
}

.icons-area .Polaris-Icon {
    width: 3rem;
    height: 3rem;
    margin: 10px auto;
}

.icons-area .Polaris-Icon svg {
    fill: #424548;
}

.icons-area .Polaris-Card {
    padding: 0px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 180px;
    color: #424548;
}

.icons-area .Polaris-Card:hover {
    box-shadow: 0 0 15px #b1a9a9;
    background-color: #3f4eae;
    color: #fff;
}

.icons-area .Polaris-Card:hover svg {
    fill: #fff;
}

.HomepageContent .icons-area button.Polaris-Link {
    width: 100%;
}

.recommendation-offer-products button.Polaris-Button.Polaris-Button--primary {
    align-self: flex-end;
    margin: 10px;
}

.recommendation-offer-products .Polaris-Card {
    display: flex;
    flex-direction: column;
}

.label-with-icon {
    display: inline-flex;
}

.label-with-icon .Polaris-Icon {
    margin: 0px 5px;
}

.layout-2 .inCart_desc_outr {
    flex-direction: column;
    margin-left: 5px;
}

.layout-2 .inCart_desc_outr>* {
    width: auto;
    margin: 5px 0 0;
    justify-content: flex-start;
}

.layout-2 .inCart_desc_outr button.OfferBtn {
    width: 150px;
    min-width: unset;
    max-width: unset;
    margin: 0;
}

#boost-bar-slider {
    height: 40px;
    position: relative;
}

#boost-bar-slider .content {
    position: absolute;
    transition: opacity 1s ease-in;
    left: 0;
    right: 0;
}

#boost-bar-slider .content+.content {
    opacity: 0;
}

.incart-offer-wrapper {
    display: flex;
    justify-content: center;
}

.mobile-preview .offer_wrapper {
    max-width: 420px;
}

/* .mobile-preview .OfferBtn,
.mobile-preview .OfferBtn:hover,
.mobile-preview .OfferBtn:active,
.mobile-preview .OfferBtn:focus {
    padding: 5px 10px;
    // min-width: 120px;
} */

.mobile-preview .offer_text {
    width: auto;
}

.card-heading-with-toggle svg.Polaris-Icon__Svg {
    fill: #5c6ac4;
}

.custom-card-heading .Polaris-Stack>.Polaris-Stack__Item {
    margin-top: 8px;
    margin-bottom: 8px;
}

.custom-card-heading .Polaris-Stack>.Polaris-Stack__Item:nth-child(2) {
    margin-left: 5px;
}

.custom-card-heading .Polaris-Stack {
    align-items: center;
}

.product-cartbar-offer {
    margin-bottom: 20px;
}

.component-all-settings .Polaris-Tabs__Wrapper .Polaris-Tabs__Tab:focus,
.component-all-settings .Polaris-Tabs__Wrapper .Polaris-Tabs__DisclosureActivator:focus {
    box-shadow: none;
}

button.OfferBtn.large {
    width: 200px;
    padding: 14px 8px;
    font-size: 120%;
}

button.OfferBtn.small {
    min-width: unset;
    padding: 5px 8px;
    font-size: 80%;
}






/* responsive css code */
@media screen and (min-width: 767px) {
    .message-conditions .Polaris-FormLayout__Items {
        align-items: center;
    }
}

@media screen and (max-width: 1500px) {
    .oneT-ofr-outr {
        width: 100%;
    }

    .ofr-outr-right {
        width: 55%;
    }

    .ofr-outr-left {
        width: 40%;
    }
}

@media screen and (max-width: 1025px) {
    .hlanding_page {
        background-size: cover;
        background-repeat: no-repeat;
    }

    .hlanding_page .Polaris-EmptyState--withinPage .Polaris-EmptyState__Details {
        margin-left: 50px;
    }
}


@media screen and (max-width: 840px) {

    .ofr-outr-right .white-btn,
    .ofr-outr-right .blue-btn {
        padding: 15px 10px;
        font-size: 14px;
    }

    /*  .cartboost-app-menu .Polaris-Card {
        flex-direction: column;
        align-items: center;
    } */
}

@media screen and (max-width: 800px) {
    .hlanding_page .Polaris-EmptyState--withinPage .Polaris-EmptyState__Details {
        margin-left: 0px;
    }
}

@media screen and (max-width: 768px) {

    /*--------------*/
    .upsells-outr .Polaris-Page-Header.Polaris-Page-Header--mobileView {
        float: left;
        width: 100%;
    }

    .upsells-outr .Polaris-Page .Polaris-DisplayText.Polaris-DisplayText--sizeLarge {
        float: left;
    }

    .upsells-outr .Polaris-Page-Header__TitleActionMenuWrapper,
    .incart-upsells-outr .Polaris-Page-Header__TitleActionMenuWrapper {
        float: left;
        width: auto;
        margin-top: 25px;
    }

    .upsells-outr .Polaris-Page__Content {
        float: left;
        width: 100%;
    }

    .upsells-outr .Polaris-Page-Header--mobileView .Polaris-Page-Header__PrimaryActionWrapper {
        float: right;
    }

    /*--------------*/
    .incart-upsells-outr .Polaris-Page .Polaris-DisplayText.Polaris-DisplayText--sizeLarge {
        float: left;
    }

    .ofr-outr-right .white-btn,
    .ofr-outr-right .blue-btn {
        padding: 15px 10px;
        font-size: 14px;
    }

    .incart-upsells-outr .Polaris-Page__Content {
        float: left;
        width: 100%;
    }

    .incart-upsells-outr .Polaris-Page-Header--mobileView .Polaris-Page-Header__PrimaryActionWrapper {
        float: right;
        width: auto;
    }

    .offer_wrapper {
        flex-direction: row;
    }

    .offer_wrapper>* {
        margin: 5px auto;
        width: 100%;
    }

    /* .image_wrap {
        display: flex;
    } */

    .OfferBtn,
    .OfferBtn:hover,
    .OfferBtn:active,
    .OfferBtn:focus {
        font-size: 12px;
        margin-left: 0;
        min-width: unset;
        width: auto;
        padding: 5px 10px;
    }

    .button_wrapper {
        justify-content: flex-start;
    }

    .image_wrap {
        width: auto;
    }

    .inCart_desc_outr {
        display: block;
        width: 100% !important;
        margin-left: 15px;
    }

    .offer_text {
        width: 100%;
        padding-bottom: 5px;
    }

    .add-incart-upsells .Polaris-FormLayout--condensed .Polaris-FormLayout__Item {
        min-width: fit-content;
    }

    .HomepageContent .Polaris-Heading {
        font-size: 22px;
        font-weight: 500;
    }

    hr.blue-border {
        border: 2px solid #1640a5;
        max-width: 100px;
        margin: 15px auto 20px;
    }

    .HomepageContent .Polaris-Subheading {
        font-size: 16px;
        font-weight: 500;
        margin: 20px auto 40px;
    }

    .HomepageContent .logo-div-sec img {
        max-width: 160px;
    }

    .HomepageContent .logo-div-sec {
        margin: 20px auto;
    }

    .mobile-homescreen {
        color: #424548;
        text-align: left;
    }

    .mobile-homescreen .Polaris-ResourceList__ItemWrapper {
        padding: 5px 0;
    }

    .mobile-homescreen .Polaris-ResourceList__ItemWrapper:hover,
    .mobile-homescreen .Polaris-ResourceList__ItemWrapper:active,
    .mobile-homescreen .Polaris-ResourceList__ItemWrapper:focus {
        background-color: #3f4eae;
        color: #fff;
    }

    .mobile-homescreen .Polaris-ResourceItem:hover:not(.Polaris-ResourceItem--newDesignLanguage) {
        background-image: unset !important;
    }

    .mobile-homescreen .Polaris-ResourceList__ItemWrapper:hover svg {
        fill: #fff;
    }

    .mobile-homescreen .Polaris-ResourceList__ItemWrapper * {
        background: transparent;
    }

    .mobile-homescreen .Polaris-Icon svg {
        fill: #424548;
    }


    .layout-1 .inCart_desc_outr {
        display: flex;
    }

    .layout-1 .offer_text {
        // width: 50%;
    }

    .layout-1 .button_wrapper {
        // width: 50%;
        justify-content: flex-end;
    }

    #boost-bar-slider {
        height: auto;
    } 
    
    #boost-bar-slider .mobile-content {
        visibility: hidden;
    }

    #boost-bar-slider .mobile-content+.mobile-content {
        display: none;
    }
}

@media screen and (max-width: 767px) {
    #new-sale .Polaris-FormLayout__Item {
        flex-basis: 100%;
    }
}

@media screen and (max-width: 350px) {
    .layout-1 .OfferBtn {
        // min-width: 100px;
    }
}

@media screen and (min-width: 520px) and (max-width: 768px) {
    .layout-1 .offer_text {
        width: 65%;
    }

    .layout-1 .button_wrapper {
        width: 35%;
        justify-content: flex-end;
    }
}

@media screen and (max-width: 766px) {
    .message-conditions .Polaris-FormLayout__Items {
        flex-direction: column;
    }

    .message-conditions .Polaris-FormLayout__Item {
        flex: 1 1 auto !important;
        min-width: unset !important;
    }
}

@media screen and (max-width: 743px) {
    .poz-rel {
        padding-right: 25px;
    }

    .hlanding_page .Polaris-EmptyState--withinPage .Polaris-EmptyState__Details {
        text-align: center;
    }

    .hlanding_page .Polaris-DisplayText--sizeMedium {
        font-size: 20px;
    }

    .hlanding_page .Polaris-EmptyState__Content {
        font-size: 16px;
    }

    .hlanding_page {
        background-image: url('home-mobile-bg.jpg');
        background-size: cover;
        background-repeat: no-repeat;
    }

    .hlanding_page .Polaris-EmptyState {
        margin-top: 50px;
        padding-top: 70px;
        padding-bottom: 300px;
    }

    .hlanding_page .Polaris-Stack__Item {
        flex: 1 0 auto;
    }

    .myprdctlist .Polaris-Stack__Item:nth-child(2) .pqty {
        display: none;
    }

    .upsells-outr .Polaris-Stack--alignmentCenter .Polaris-Stack__Item {
        float: left;
        width: 100%;
    }
}

@media screen and (max-width: 503px) {
    .edit-coupon-ofr .Polaris-FormLayout--condensed .Polaris-FormLayout__Item {
        flex-basis: 100%;
    }
}

@media screen and (max-width: 490px) {
    .hlanding_page .Polaris-DisplayText--sizeMedium {
        line-height: 65px;
        float: left;
        width: 100%;
    }

    /*  .Polaris-Stack > .Polaris-Stack__Item{
        float: left;
        width: 100%;
    }*/
    .edit-shpng-ofr .Polaris-Page-Header__TitleActionMenuWrapper {
        float: left;
        width: auto;
        margin-top: 25px;
    }

    .edit-shpng-ofr .Polaris-Page-Header--separator {
        float: left;
        width: 100%;
    }

    .edit-shpng-ofr .Polaris-Page-Header.Polaris-Page-Header--separator.Polaris-Page-Header--mobileView .Polaris-Page-Header__PrimaryActionWrapper {
        float: right;
    }

    /* .edit-coupon-ofr .Polaris-Page-Header__TitleActionMenuWrapper {
        float: left;
        width: auto;
        margin-top: 25px;
    }

    .edit-coupon-ofr .Polaris-Page-Header--separator {
        float: left;
        width: 100%;
    }

    .edit-coupon-ofr .Polaris-Page-Header.Polaris-Page-Header--separator.Polaris-Page-Header--mobileView .Polaris-Page-Header__PrimaryActionWrapper {
        float: right;
    } */

    .edit-prdct-ofr .Polaris-Page-Header__TitleActionMenuWrapper {
        float: left;
        width: auto;
        margin-top: 25px;
    }

    .edit-prdct-ofr .Polaris-Page-Header--separator {
        float: left;
        width: 100%;
    }

    .edit-prdct-ofr .Polaris-Page-Header.Polaris-Page-Header--separator.Polaris-Page-Header--mobileView .Polaris-Page-Header__PrimaryActionWrapper {
        float: right;
    }

    .edit-shpng-ofr .Polaris-Page .Polaris-DisplayText.Polaris-DisplayText--sizeLarge {
        font-size: 14px;
    }

    .edit-coupon-ofr .Polaris-Page .Polaris-DisplayText.Polaris-DisplayText--sizeLarge {
        font-size: 14px;
    }

    .edit-prdct-ofr .Polaris-Page .Polaris-DisplayText.Polaris-DisplayText--sizeLarge {
        font-size: 14px;
    }

    .faq-outr .Polaris-Page .Polaris-DisplayText.Polaris-DisplayText--sizeLarge {
        font-size: 14px;
    }

    .faq-outr .Polaris-Card {
        font-size: 12px;
    }

    .incart-upsells-outr .Polaris-Page .Polaris-DisplayText.Polaris-DisplayText--sizeLarge {
        font-size: 16px;
    }

    .add-incart-upsells .Polaris-Page .Polaris-DisplayText.Polaris-DisplayText--sizeLarge {
        font-size: 16px;
    }

    .add-incart-upsells .Polaris-Page-Header__TitleActionMenuWrapper {
        float: left;
        width: auto;
        margin-top: 25px;
    }

    .add-incart-upsells .Polaris-Page-Header--separator {
        float: left;
        width: 100%;
    }

    .add-incart-upsells .Polaris-Page-Header.Polaris-Page-Header--separator.Polaris-Page-Header--mobileView .Polaris-Page-Header__PrimaryActionWrapper {
        float: right;
    }

    .add-incart-upsells .Polaris-Page__Content {
        float: left;
        width: 100%;
    }

    .myprdctlist .Polaris-Button--primary {
        padding: 0 30px;
        float: left;
        margin: 6%;
        width: 87%;
    }

    .upsells-outr .Polaris-Page .Polaris-DisplayText.Polaris-DisplayText--sizeLarge {
        font-size: 16px;
    }

    .incart-upsells-outr .Polaris-Page .Polaris-DisplayText.Polaris-DisplayText--sizeLarge {
        font-size: 16px;
    }

    .myprdctlist .Polaris-Stack>.Polaris-Stack__Item {
        min-width: auto;
    }

    .ofr-outr-right h2,
    .ofr-outr-right p {
        display: none;
    }

    .ofr-outr-right {
        width: 100%;
    }

    .ofr-outr-left {
        width: 100%;
    }

    .ofr-outr-left h2,
    .ofr-outr-left p {
        display: block;
    }

    .oneT-ofr-outr {
        float: left;
    }

    .ofr-outr-left {
        height: auto;
    }

    .ofr-outr-left img {
        position: relative;
    }

    .ofr-outr-left h2 {
        font-size: 14px;
    }

    .offer_wrapper {
        display: flex;
        padding: 10px;
        clear: both;
        flex-direction: row;
        max-width: 100%;
    }

    .ofr-outr-left p {
        font-size: 12px;
    }

    .ofr-outr-right .prdct-detail h3 {
        font-size: 20px;
    }

    .ofr-outr-right .white-btn,
    .ofr-outr-right .blue-btn {
        font-size: 14px;
        margin: 0 2px;
    }

    .ofr-outr-right .white-btn,
    .ofr-outr-right .blue-btn {
        padding: 15px 30px;
        font-size: 14px;
    }

    // .inCart_desc_outr {
    //     display: inline-block;
    // }

    .offer_text {
        display: flex;
    }


    #upsell-trigger-collapsible .Polaris-FormLayout--condensed .Polaris-FormLayout__Item {
        flex-basis: 100%;
        min-width: unset;
    }
}

@media screen and (max-width: 420px) {

    .upsells-outr .Polaris-Page-Header__TitleActionMenuWrapper,
    .incart-upsells-outr .Polaris-Page-Header__TitleActionMenuWrapper {
        width: 100%;
    }

    .upsells-outr .Polaris-Page-Header--mobileView .Polaris-Page-Header__PrimaryActionWrapper,
    .incart-upsells-outr .Polaris-Page-Header--mobileView .Polaris-Page-Header__PrimaryActionWrapper {
        float: left;
    }

    span.upcoming-badge {
        display: flex;
        justify-content: center;
        flex-direction: column-reverse;
        text-align: center;
    }

    span.upcoming-badge>span.Polaris-Badge--statusInfo {
        justify-content: center;
    }

    .logo-div-sec {
        max-width: 110px;
    }


}

@media screen and (max-width: 985px) {
    .vertical-border {
        display: none;
    }
}